// local imports
import shippingIcon from "./assets/free-shiping-icon.svg";
import paymentIcon from "./assets/quick-payment-icon.svg";
import supportIcon from "./assets/support-icon.svg";
import productOneIcon from "./assets/product-one.svg";
import productTwoIcon from "./assets/produc-two.svg";
import productThreeIcon from "./assets/product-three.svg";
import plantIcon from "./assets/paper-plane-solid.svg";
import sunIcon from "./assets/location-dot-solid.svg";
import waterIcon from "./assets/user-nurse-solid.svg";
import temperatureIcon from "./assets/monument-solid.svg";
import historylogo from "./assets/landmark-solid.svg";
import eye from "./assets/eye-solid.svg";
import person from "./assets/person.svg";


export const heroTitle = "SABOTIN";
export const heroSubtitle =
  "Vzpetina z bogato zgodovino in čudovitim razgledom";

export const services = [
  {
    title: "Razgled",
    subtitle: "Dvignjena lega hriba omogoča panoramski razgled na okoliško pokrajino. Ko sonce zaide, boste deležni spektakla barv, ki vas bodo navdušili. Je popolno ozadje za zajemanje teh nepozabnih trenutkov.",
    icon: eye,
  },
  {
    title: "Zgodovina",
    subtitle: "Ljubiteljem zgodovine in radovednežem ponuja Sabotin enkratno priložnost za spoznavanje dogodkov iz prve svetovne vojne. Za tiste, ki se želijo poglobiti v preteklost, so na voljo vodeni ogledi, zgodovinske razstave in interaktivni prikazi. Pridobite lahko celovito razumevanje bitk, ki so potekale tukaj, in njihov vpliv na svet.",
    icon: historylogo,
  },
  {
    title: "Rekreacija",
    subtitle: "Sabotin je veličastno umeščen v osrčje Goriške regije in je raj za pohodnike, kjer visoki vrh in razgibane poti vabijo pustolovce vseh ravni. Ne glede na to, ali ste izkušen pohodnik ali začetnik, navdušenec nad naravo, naš hrib ponuja vrsto pohodniških doživetij, ki vas bodo navdušila in boste hrepeneli po več.",
    icon: person,
  },
];

export const productsTitle = "Najbolj prodajani izdelki";
export const productsBtnText = "Vsa kolekcija";

export const products = [
  {
    title: "Kratka majica",
    price: "€20",
    img: productOneIcon,
  },
  {
    title: "Majica z kapuco",
    price: "€35",
    img: productTwoIcon,
  },
  {
    title: "Kapa",
    price: "€15",
    img: productThreeIcon,
  },
];

export const referenceTitle = "Odkrij naravne lepote in zgodovinske trenutke";
export const referenceSubtitle = "Naravni čudeži bodo zagotovo očarali vaše srce";

export const careTitle = "Zgodovinski pomen";
export const careSubtitle = "Povežite se";

export const careList = [
  {
    title: "Mesto spomina",
    subtitle:
      "Obiskovalce Sabotina spodbujamo, da se poklonijo padlim vojakom, ki so se hrabro borili na teh tleh. Na hribu je vojni spomenik, kraj tihega razmišljanja, kjer lahko počastite spomin na tiste, ki so dali svoja življenja za svoje države.",
    img: sunIcon,
  },
  {
    title: "Kraj zdravljenja in sprave",
    subtitle:
      "Medtem ko Sabotin nosi brazgotine svoje vojne zgodovine, je postal tudi kraj zdravljenja in sprave. Mirna naravna okolica ponuja tolažbo tistim, ki želijo razmišljati o lekcijah zgodovine in pomenu miru. Služi kot ganljiv opomin na trdoživ človeški duh, ki išče tolažbo in spokojnost tudi sredi kaosa.",
    img: waterIcon,
  },
  {
    title: "Načrtujte svoj obisk in se poklonite",
    subtitle:
      "Ko obiščete Sabotin, si vzemite trenutek in razmislite o njegovi dvojni identiteti. Mestu dih jemajoče naravne lepote in mestu zgodovinskega pomena. Raziščite bojišča, obiščite vojni spomenik in se potopite v bogato zgodovino, ki prežema ta posvečena območja. To je edinstvena priložnost, da se poklonite preteklosti in hkrati sprejmete mir sedanjosti, zaradi česar bo vaš obisk Sabotina resnično pomembna izkušnja.",
    img: plantIcon,
  },
  {
    title: "Priča zgodovine",
    subtitle:
      "Sabotin ni le zatočišče naravnih lepot, nosi tudi brazgotine zgodovine, saj je bilo prizorišče pomembne bitke med prvo svetovno vojno. To nadstropno pobočje je doživelo svoj delež zmag in tragedij, njegov zgodovinski pomen pa dodaja globino njegovi privlačnosti.",
    img: temperatureIcon,
  },
];

export const newsletter = "Elektronske novice";

export const footerLinksColumnOne = [
  {
    title: "Podpora",
    links: ["O nas", "Povezovanje", "Blog"],
  },
  {
    title: "Uporabni naslovi",
    links: ["Plačilo", "Pogoji poslovanja", "Zasebnost in piškotki"],
  },
];

export const footerLinksColumnTwo = [
  {
    title: "Trgovina",
    links: ["Razprodaja", "Vsa kolekcija"],
  },
  {
    title: "Elektronski naslov",
    links: [
         "info@sabotin.net",
    ],
  },
];
